import { createSlice } from '@reduxjs/toolkit';
import i18n from '../../i18n';
import {
  BACKGROUND_CONSULT_MODE,
  BACKGROUND_CREATE_MODE,
  BACKGROUND_DELETE_MODE,
  BACKGROUND_UPDATE_MODE,
  CALCULATE_MODE,
  CONSULT_MODE,
  CREATE_MODE,
  LOCKED_MODE,
  NONE_BACKGROUND_MODE,
  NONE_MODE,
  NONE_SUB_BACKGROUND_MODE,
  NONE_SUB_MODE,
  REGISTER_MODE,
  RELEASE_MODE,
  RETRANSMITIR_MODE,
  LIBERAR_MODE,
  SUB_BACKGROUND_CONSULT_MODE,
  SUB_BACKGROUND_CREATE_MODE,
  SUB_BACKGROUND_DELETE_MODE,
  SUB_BACKGROUND_UPDATE_MODE,
  SUB_CONSULT_MODE,
  SUB_CREATE_MODE,
  SUB_UPDATE_MODE,
  UPDATE_MODE,
} from '../mode';
import { NO_REFRESH, REFRESH } from '../refresh';
import {
  ALTERNATIVE_LOADING_STATUS,
  FAILURE_STATUS,
  IDLE_STATUS,
  LOADING_STATUS,
  PREPARING_ACTION_STATUS,
  SUCCESS_STATUS,
} from '../status';
import {
  activateByIdFromFaturaAsync,
  conferirByIdFromFaturaAsync,
  diagnosticoDiByIdAsync,
  transmissaoDuimpByIdAsync,
  registrarDuimpByIdAsync,
  duplicarByIdFromFaturaAsync,
  fetchAllFromFaturaAsync,
  fetchByIdFromFaturaAsync,
  inactivateByIdFromFaturaAsync,
  liberarByIdFromFaturaAsync,
  fetchAllDecImportWithPaginationAsync,
  fetchMercadoriasByFaturaIdAsync,
  fetchAdicoesByDiDuimpIdAsync,
  fetchCapaDiDuimpByIdFromFaturaAsync,
  updateMercadoriaAsync,
  updateAdicaoAsync,
  transmissaoDiagnosticoDuimpByIdAsync,
  obterPlanilhaFundamentoLegalDuimp,
  obterPlanilhaTratamentoTributarioDuimp,
  importarTratamentosTributariosThunk,
  importarFundamentosLegaisThunk,
} from './declaracaoImportacaoThunks';

const initialState = {
  status: IDLE_STATUS,
  mode: {
    main: NONE_MODE,
    background: NONE_BACKGROUND_MODE,
    sub: NONE_SUB_MODE,
    subBackground: NONE_SUB_BACKGROUND_MODE,
  },
  refresh: NO_REFRESH,
  error: null,
  response: {
    status: null,
    message: null,
    data: null,
  },
  config: {
    conditionalSteps: [i18n.t('com.muralis.qcx.transporte.label')],
  },
  list: [],
  model: {
    atributosAdicionais: {
      processo: undefined,
    },
    mercadorias: [],
    adicoes: [],
  },
  related: {
    model: {
      report: {},
      item: {},
      adicao: {},
      calculo: {},
      duplicata: {},
      registro: {},
      emissaoIcms: {},
    },
    list: {
      selectionDeclaracaoImportacao: [],
    },
  },
  historyModel: {
    last: {},
  },
  // Salva o estado anterior do formulario
  // utilizado para a função de duplicar no formulario de mercadoria
  previousForm: {},
};

const declaracaoImportacaoSlice = createSlice({
  name: 'declaracaoImportacao',
  initialState,
  reducers: {
    changeStatusTo: (state, action) => {
      state.status = action.payload.status;
    },
    loading: (state) => {
      state.status = LOADING_STATUS;
    },
    loadingDI: (state) => {
      state.status = LOADING_STATUS;
    },
    success: (state) => {
      state.status = SUCCESS_STATUS;
    },
    failure: (state) => {
      state.status = FAILURE_STATUS;
    },
    preparingAction: (state) => {
      state.status = PREPARING_ACTION_STATUS;
    },
    resetStatus: (state) => {
      state.status = IDLE_STATUS;
    },
    refresh: (state) => {
      state.refresh = REFRESH;
    },
    resetRefresh: (state) => {
      state.refresh = NO_REFRESH;
    },
    changeToCreateMode: (state) => {
      state.mode.main = CREATE_MODE;
    },
    changeToConsultMode: (state) => {
      state.mode.main = CONSULT_MODE;
    },
    changeToUpdateMode: (state) => {
      state.mode.main = UPDATE_MODE;
    },
    changeToLockMode: (state) => {
      state.mode.main = LOCKED_MODE;
    },
    changeToCalculateModeDI: (state) => {
      state.mode.main = CALCULATE_MODE;
    },
    changeToReleaseModeDI: (state) => {
      state.mode.main = RELEASE_MODE;
    },
    changeToRetransmitirModeDI: (state) => {
      state.mode.main = RETRANSMITIR_MODE;
    },
    changeToLiberarModeDI: (state) => {
      state.mode.main = LIBERAR_MODE;
    },
    changeToRegisterModeDI: (state) => {
      state.mode.main = REGISTER_MODE;
    },
    resetMode: (state) => {
      state.mode.main = NONE_MODE;
    },
    changeToBackgroundCreateMode: (state) => {
      state.mode.background = BACKGROUND_CREATE_MODE;
    },
    changeToBackgroundConsultMode: (state) => {
      state.mode.background = BACKGROUND_CONSULT_MODE;
    },
    changeToBackgroundUpdateMode: (state) => {
      state.mode.background = BACKGROUND_UPDATE_MODE;
    },
    changeToBackgroundDeleteMode: (state) => {
      state.mode.background = BACKGROUND_DELETE_MODE;
    },
    resetBackgroundMode: (state) => {
      state.mode.background = NONE_BACKGROUND_MODE;
    },
    changeToSubCreateMode: (state) => {
      state.mode.sub = SUB_CREATE_MODE;
    },
    changeToSubConsultMode: (state) => {
      state.mode.sub = SUB_CONSULT_MODE;
    },
    changeToSubUpdateMode: (state) => {
      state.mode.sub = SUB_UPDATE_MODE;
    },
    resetSubMode: (state) => {
      state.mode.sub = NONE_SUB_MODE;
    },
    changeToSubBackgroundCreateMode: (state) => {
      state.mode.subBackground = SUB_BACKGROUND_CREATE_MODE;
    },
    changeToSubBackgroundConsultMode: (state) => {
      state.mode.subBackground = SUB_BACKGROUND_CONSULT_MODE;
    },
    changeToSubBackgroundUpdateMode: (state) => {
      state.mode.subBackground = SUB_BACKGROUND_UPDATE_MODE;
    },
    changeToSubBackgroundDeleteMode: (state) => {
      state.mode.subBackground = SUB_BACKGROUND_DELETE_MODE;
    },
    resetSubBackgroundMode: (state) => {
      state.mode.subBackground = NONE_SUB_BACKGROUND_MODE;
    },
    setModel: (state, action) => {
      state.model = {
        ...action.payload,
      };
    },
    resetModel: (state) => {
      state.model = initialState.model;
    },
    setProcesso: (state, action) => {
      state.model.followUp = action.payload.followUp;
      state.model.atributosAdicionais.processo = action.payload.processo;
    },
    addToList: (state, action) => {
      state.list = [...state.list, action.payload.data];
    },
    updateOnList: (state, action) => {
      state.list = state.list.map((current) => {
        if (current.id === action.payload.data?.id) {
          return action.payload.data;
        }
        return current;
      });
    },
    setRelatedReportModel: (state, action) => {
      state.related.model.report = {
        ...action.payload,
      };
    },
    resetRelatedReportModel: (state) => {
      state.related.model.report = initialState.related.model.report;
    },
    setRelatedItemModel: (state, action) => {
      state.related.model.item = {
        ...action.payload,
      };
    },
    resetRelatedItemModel: (state) => {
      state.related.model.item = initialState.related.model.item;
    },
    setRelatedAdicaoModel: (state, action) => {
      state.related.model.adicao = {
        ...action.payload,
      };
    },
    resetRelatedAdicaoModel: (state) => {
      state.related.model.adicao = initialState.related.model.adicao;
    },
    setRelatedCalculoModel: (state, action) => {
      state.related.model.calculo = {
        ...action.payload,
      };
    },
    resetRelatedCalculoModel: (state) => {
      state.related.model.calculo = {};
    },
    setRelatedDuplicataModel: (state, action) => {
      state.related.model.duplicata = {
        ...action.payload,
      };
    },
    resetRelatedDuplicataModel: (state) => {
      state.related.model.duplicata = {};
    },
    setRelatedRegistroModel: (state, action) => {
      state.related.model.registro = {
        ...action.payload,
      };
    },
    resetRelatedRegistroModel: (state) => {
      state.related.model.registro = {};
      state.status = IDLE_STATUS;
    },
    setRelatedEmissaoIcmsModel: (state, action) => {
      state.related.model.emissaoIcms = {
        ...action.payload,
      };
    },
    setRelatedSelectionDeclaracaoImportacaoList: (state, action) => {
      state.related.list.selectionDeclaracaoImportacao = action.payload;
    },
    resetRelatedEmissaoIcmsModel: (state) => {
      state.related.model.emissaoIcms = {};
    },
    changeLastHistoryModel: (state, action) => {
      state.historyModel.last = action.payload;
    },
    resetHistoryModel: (state) => {
      state.historyModel = {};
    },
    resetLastHistoryModel: (state) => {
      state.historyModel.last = {};
    },
    setResponse: (state, action) => {
      state.response = action.payload;
    },
    clearResponse: (state) => {
      state.response = {
        status: null,
        message: null,
        data: null,
      };
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = initialState.error;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchByIdFromFaturaAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(fetchByIdFromFaturaAsync.fulfilled, (state, action) => {
        if (action.payload.response.status === 200) {
          state.model = {
            ...action.payload.response.data,
          };
          state.status = SUCCESS_STATUS;

          return;
        }

        state.status = IDLE_STATUS;
      })
      .addCase(fetchByIdFromFaturaAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarDIDUIMP', { erro: action.error.message }),
        };
      })
      .addCase(fetchCapaDiDuimpByIdFromFaturaAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(fetchCapaDiDuimpByIdFromFaturaAsync.fulfilled, (state, action) => {
        if (action.payload.response.status === 200) {
          state.model = {
            ...action.payload.response.data,
            atributosAdicionais: {
              adicoes: state.model?.atributosAdicionais?.adicoes,
              ...action.payload.response.data?.atributosAdicionais,
            },
            mercadorias: state.model?.mercadorias,
          };
          state.status = SUCCESS_STATUS;

          return;
        }

        state.status = IDLE_STATUS;
      })
      .addCase(fetchCapaDiDuimpByIdFromFaturaAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarDIDUIMP', { erro: action.error.message }),
        };
      })
      .addCase(updateMercadoriaAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(updateMercadoriaAsync.fulfilled, (state, action) => {
        if (action.payload.response.status === 200) {
          // atualiza estado da lista de mercadorias
          const updatedMercadoria = action.payload.response.data.mercadoria;
          const updatedMercadoriaIndex = state.model?.mercadorias?.findIndex((m) => m.id === updatedMercadoria?.id);

          if (updatedMercadoriaIndex > -1) {
            state.model.mercadorias[updatedMercadoriaIndex] = updatedMercadoria;
          }

          state.related.model.item = updatedMercadoria;

          // atualizado estado da lista de adicoes
          const adicaoDestinoId = action.payload.response.data?.mercadoriaAdicaoDestinoId;
          const adicoesCapa = action.payload.response.data?.adicoesCapa;

          if (adicaoDestinoId && adicoesCapa) {
            const { adicoes } = state.model.atributosAdicionais;
            const novasAdicoes = adicoesCapa.map((adicaoCapa) => {
              const adicaoAtual = adicoes.find((a) => a.id === adicaoCapa.id);

              let novasMercadorias = adicaoAtual
                ? adicaoAtual.mercadorias.filter((m) => m.id !== updatedMercadoria.id)
                : [];

              if (adicaoCapa.id === adicaoDestinoId) {
                novasMercadorias.push(updatedMercadoria);
              }

              let itemAdicaoCount = 0;
              novasMercadorias = novasMercadorias.map((m) => {
                itemAdicaoCount += 1;

                return {
                  ...m,
                  itemAdicao: itemAdicaoCount,
                };
              });

              return {
                ...adicaoCapa,
                mercadorias: novasMercadorias,
              };
            });

            state.model.atributosAdicionais.adicoes = novasAdicoes;

            const adicaoDestino = novasAdicoes.find((a) => a.id === adicaoDestinoId);
            state.related.model.adicao = adicaoDestino;
          } else {
            const { adicoes } = state.model.atributosAdicionais;

            for (let i = 0; i < adicoes.length; i += 1) {
              const adicao = adicoes[i];
              const mercadoriaIndex = adicao.mercadorias.findIndex((m) => m.id === updatedMercadoria.id);

              if (mercadoriaIndex > -1) {
                state.model.atributosAdicionais.adicoes[i].mercadorias[mercadoriaIndex] = updatedMercadoria;
                break;
              }
            }
          }

          // volta para estado de consult
          state.status = SUCCESS_STATUS;
          state.mode.main = CONSULT_MODE;
          state.mode.sub = SUB_CONSULT_MODE;

          return;
        }

        state.status = IDLE_STATUS;
      })
      .addCase(updateMercadoriaAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarDIDUIMP', { erro: action.error.message }),
        };
      })
      .addCase(fetchMercadoriasByFaturaIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(fetchMercadoriasByFaturaIdAsync.fulfilled, (state, action) => {
        if (action.payload.response.status === 200 && state.model) {
          state.model.mercadorias = action.payload.response.data;
          state.status = SUCCESS_STATUS;

          return;
        }

        state.status = IDLE_STATUS;
      })
      .addCase(fetchMercadoriasByFaturaIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarDIDUIMP', { erro: action.error.message }),
        };
      })
      .addCase(fetchAdicoesByDiDuimpIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(fetchAdicoesByDiDuimpIdAsync.fulfilled, (state, action) => {
        if (action.payload.response.status === 200 && state.model?.atributosAdicionais) {
          const adicoes = action.payload.response.data;

          state.model.atributosAdicionais = {
            ...state.model.atributosAdicionais,
            adicoes,
          };

          const mercadorias = adicoes.map((adicao) => adicao.mercadorias).flat();

          state.model.mercadorias = mercadorias;

          state.status = SUCCESS_STATUS;

          return;
        }

        state.status = IDLE_STATUS;
      })
      .addCase(fetchAdicoesByDiDuimpIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarDIDUIMP', { erro: action.error.message }),
        };
      })
      .addCase(fetchAllFromFaturaAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchAllFromFaturaAsync.fulfilled, (state, action) => {
        state.list = action.payload.data;
        state.status = IDLE_STATUS;
        state.refresh = NO_REFRESH;
      })
      .addCase(fetchAllFromFaturaAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarListaDIDUIMP', { erro: action.error.message }),
        };
        state.refresh = NO_REFRESH;
      })
      .addCase(fetchAllDecImportWithPaginationAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchAllDecImportWithPaginationAsync.fulfilled, (state, action) => {
        state.list = action.payload.data.itens;
        state.status = IDLE_STATUS;
        state.refresh = NO_REFRESH;
        state.count = action?.payload?.data?.totalNumberOfItens;
      })
      .addCase(fetchAllDecImportWithPaginationAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarListaDIDUIMP', { erro: action.error.message }),
        };
        state.refresh = NO_REFRESH;
      })
      .addCase(duplicarByIdFromFaturaAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(duplicarByIdFromFaturaAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const requestPayload = action.meta.arg;

        const declaracaoImportacaoDuplicada = action.payload.response.data;

        const { processo } = declaracaoImportacaoDuplicada.atributosAdicionais;
        state.response.message = i18n.t('com.muralis.qcx.mensagem.declaracaoImportacaoDuplicadaProcesso', {
          processo,
          origem: requestPayload?.meta?.from?.processo,
        });

        state.list = [...state.list, declaracaoImportacaoDuplicada];

        state.historyModel.last = {
          processo,
        };

        if (state.response.status === 201) {
          state.refresh = REFRESH;
        }
      })
      .addCase(duplicarByIdFromFaturaAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: action.payload?.message
            ? i18n.t('com.muralis.qcx.erro.erroDuplicarDIEspecifico', { mensagem: action.payload?.message })
            : i18n.t('com.muralis.qcx.erro.erroDuplicarDI'),
        };
      })
      .addCase(conferirByIdFromFaturaAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(conferirByIdFromFaturaAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const declaracaoImportacaoConferida = action.payload.response.data;

        const { processo } = declaracaoImportacaoConferida.atributosAdicionais;

        state.list = state.list.map((declaracaoImportacao) =>
          declaracaoImportacao?.id === declaracaoImportacaoConferida?.id
            ? declaracaoImportacaoConferida
            : declaracaoImportacao
        );

        state.response.message = i18n.t('com.muralis.qcx.mensagem.declaracaoImportacaoConferido', { processo });

        state.historyModel.last = {
          processo,
        };

        if (state.response.status === 200) {
          state.refresh = REFRESH;
        }
      })
      .addCase(conferirByIdFromFaturaAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: action.payload?.message
            ? i18n.t('com.muralis.qcx.erro.erroConferirDIEspecifico', { mensagem: action.payload?.message })
            : i18n.t('com.muralis.qcx.erro.erroConferir'),
        };
      })
      .addCase(liberarByIdFromFaturaAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(liberarByIdFromFaturaAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const declaracaoImportacaoLiberada = action.payload.response.data;

        const { processo } = declaracaoImportacaoLiberada.atributosAdicionais;

        state.list = state.list.map((declaracaoImportacao) =>
          declaracaoImportacao?.id === declaracaoImportacaoLiberada?.id
            ? declaracaoImportacaoLiberada
            : declaracaoImportacao
        );

        state.response.message = i18n.t('com.muralis.qcx.mensagem.DILiberadaRegistro', { processo });

        state.historyModel.last = {
          processo,
        };

        if (state.response.status === 200) {
          state.refresh = REFRESH;
        }
      })
      .addCase(liberarByIdFromFaturaAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: action.payload?.message
            ? i18n.t('com.muralis.qcx.erro.erroLiberarDIRegistroEspecifico', { mensagem: action.payload?.message })
            : i18n.t('com.muralis.qcx.erro.erroLiberarDIRegistro'),
        };
      })
      .addCase(activateByIdFromFaturaAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(activateByIdFromFaturaAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const { atributosAdicionais } = action.payload.response.data;
        state.response.message = i18n.t('com.muralis.qcx.mensagem.DIDUIMPAtivada', {
          atributo: atributosAdicionais?.processo,
        });

        if (state.response.status === 200) {
          state.list = state.list.map((current) =>
            current.id === action.payload.response.data.id ? action.payload.response.data : current
          );
        }
      })
      .addCase(activateByIdFromFaturaAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroTentarAtivarDIDUIMP', { mensagem: action.error.message }),
        };
      })
      .addCase(inactivateByIdFromFaturaAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(inactivateByIdFromFaturaAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const { atributosAdicionais } = action.payload.response.data;
        state.response.message = i18n.t('com.muralis.qcx.mensagem.DIDUIMPExcluida', {
          atributo: atributosAdicionais?.processo,
        });

        if (state.response.status === 200) {
          state.list = state.list.map((current) =>
            current.id === action.payload.response.data.id ? action.payload.response.data : current
          );
        }
      })
      .addCase(inactivateByIdFromFaturaAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroExcluirDIDUIMP', { mensagem: action.error.message }),
        };
      })
      .addCase(diagnosticoDiByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(diagnosticoDiByIdAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;
      })
      .addCase(diagnosticoDiByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroDiagnosticoDIDUIMP', { mensagem: action.error.message }),
        };
      })
      .addCase(transmissaoDuimpByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(transmissaoDuimpByIdAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;
      })
      .addCase(transmissaoDuimpByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroDiagnosticoDIDUIMP', { mensagem: action.error.message }),
        };
      })
      .addCase(updateAdicaoAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(updateAdicaoAsync.fulfilled, (state, action) => {
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const updatedAdicao = action.payload.response.data;
        const updatedAdicaoIndex = state.model?.atributosAdicionais?.adicoes?.findIndex(
          (a) => a.id === updatedAdicao?.id
        );

        if (updatedAdicaoIndex > -1) {
          state.model.atributosAdicionais.adicoes[updatedAdicaoIndex] = updatedAdicao;
        }

        const updatedMercadorias = updatedAdicao?.mercadorias ?? [];

        for (let i = 0; i < updatedMercadorias.length; i += 1) {
          const updatedMercadoria = updatedMercadorias[i];
          const updatedMercadoriaIndex = state.model?.mercadorias?.findIndex((m) => m.id === updatedMercadoria.id);

          if (updatedMercadoriaIndex > -1) {
            state.model.mercadorias[updatedMercadoriaIndex] = updatedMercadoria;
          }
        }

        state.related.model.adicao = updatedAdicao;
        state.mode.main = CONSULT_MODE;
        state.mode.sub = SUB_CONSULT_MODE;
        state.status = SUCCESS_STATUS;
      })
      .addCase(updateAdicaoAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: `Erro ao atualizar: ${action.error.message} `,
        };
      })
      .addCase(registrarDuimpByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(registrarDuimpByIdAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;
      })
      .addCase(registrarDuimpByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroDiagnosticoDIDUIMP', { mensagem: action.error.message }),
        };
      })
      .addCase(transmissaoDiagnosticoDuimpByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(transmissaoDiagnosticoDuimpByIdAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;
      })
      .addCase(transmissaoDiagnosticoDuimpByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroDiagnosticoDIDUIMP', { mensagem: action.error.message }),
        };
      })
      .addCase(obterPlanilhaFundamentoLegalDuimp.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(obterPlanilhaFundamentoLegalDuimp.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
      })
      .addCase(obterPlanilhaFundamentoLegalDuimp.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroDiagnosticoDIDUIMP', { mensagem: action.error.message }),
        };
      })
      .addCase(obterPlanilhaTratamentoTributarioDuimp.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(obterPlanilhaTratamentoTributarioDuimp.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
      })
      .addCase(obterPlanilhaTratamentoTributarioDuimp.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroDiagnosticoDIDUIMP', { mensagem: action.error.message }),
        };
      })
      .addCase(importarTratamentosTributariosThunk.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(importarTratamentosTributariosThunk.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
      })
      .addCase(importarTratamentosTributariosThunk.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroDiagnosticoDIDUIMP', { mensagem: action.error.message }),
        };
      })
      .addCase(importarFundamentosLegaisThunk.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(importarFundamentosLegaisThunk.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
      })
      .addCase(importarFundamentosLegaisThunk.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroDiagnosticoDIDUIMP', { mensagem: action.error.message }),
        };
      });
  },
});

const {
  changeStatusTo,
  loading,
  loadingDI,
  success,
  failure,
  preparingAction,
  resetStatus,
  refresh,
  resetRefresh,
  changeToCreateMode,
  changeToConsultMode,
  changeToUpdateMode,
  changeToLockedMode,
  resetMode,
  changeToBackgroundCreateMode,
  changeToBackgroundConsultMode,
  changeToBackgroundUpdateMode,
  changeToBackgroundDeleteMode,
  resetBackgroundMode,
  changeToSubConsultMode,
  changeToSubUpdateMode,
  changeToSubCreateMode,
  resetSubMode,
  changeToSubBackgroundCreateMode,
  changeToSubBackgroundConsultMode,
  changeToSubBackgroundUpdateMode,
  changeToSubBackgroundDeleteMode,
  resetSubBackgroundMode,
  setModel,
  setProcesso,
  resetModel,
  addToList,
  updateOnList,
  setRelatedReportModel,
  resetRelatedReportModel,
  setRelatedItemModel,
  resetRelatedItemModel,
  setRelatedAdicaoModel,
  resetRelatedAdicaoModel,
  setRelatedCalculoModel,
  resetRelatedCalculoModel,
  setRelatedDuplicataModel,
  resetRelatedDuplicataModel,
  setRelatedRegistroModel,
  resetRelatedRegistroModel,
  setRelatedEmissaoIcmsModel,
  setRelatedSelectionDeclaracaoImportacaoList,
  resetRelatedEmissaoIcmsModel,
  changeLastHistoryModel,
  changeToRegisterModeDI,
  changeToCalculateModeDI,
  changeToReleaseModeDI,
  changeToRetransmitirModeDI,
  changeToLiberarModeDI,
  resetHistoryModel,
  resetLastHistoryModel,
  setResponse,
  clearResponse,
  setError,
  clearError,
} = declaracaoImportacaoSlice.actions;

const declaracaoImportacaoActions = declaracaoImportacaoSlice.actions;

export {
  addToList,
  changeLastHistoryModel,
  changeStatusTo,
  changeToBackgroundConsultMode,
  changeToBackgroundCreateMode,
  changeToBackgroundDeleteMode,
  changeToBackgroundUpdateMode,
  changeToConsultMode,
  changeToCreateMode,
  changeToLockedMode,
  changeToSubBackgroundConsultMode,
  changeToSubBackgroundCreateMode,
  changeToSubBackgroundDeleteMode,
  changeToSubBackgroundUpdateMode,
  changeToSubConsultMode,
  changeToSubCreateMode,
  changeToSubUpdateMode,
  changeToUpdateMode,
  changeToCalculateModeDI,
  changeToReleaseModeDI,
  changeToRetransmitirModeDI,
  changeToLiberarModeDI,
  loadingDI,
  clearError,
  clearResponse,
  declaracaoImportacaoActions,
  declaracaoImportacaoSlice,
  failure,
  loading,
  preparingAction,
  refresh,
  resetBackgroundMode,
  resetHistoryModel,
  resetLastHistoryModel,
  resetMode,
  resetModel,
  resetRefresh,
  resetRelatedAdicaoModel,
  resetRelatedCalculoModel,
  resetRelatedDuplicataModel,
  resetRelatedEmissaoIcmsModel,
  resetRelatedItemModel,
  resetRelatedRegistroModel,
  resetRelatedReportModel,
  resetStatus,
  resetSubBackgroundMode,
  resetSubMode,
  setError,
  setModel,
  setProcesso,
  setRelatedAdicaoModel,
  setRelatedCalculoModel,
  setRelatedDuplicataModel,
  setRelatedEmissaoIcmsModel,
  setRelatedItemModel,
  setRelatedRegistroModel,
  setRelatedReportModel,
  setRelatedSelectionDeclaracaoImportacaoList,
  changeToRegisterModeDI,
  setResponse,
  success,
  updateOnList,
};

export default declaracaoImportacaoSlice.reducer;
